
import axios from '@/common/js/request'

// 通过部门id查此部门及其下所有部门用户
export function selectAllUsersByDeptId(params){  
  const selectAllUsersByDeptId = axios({
      url: '/arumData/selectAllUsersByDeptId',
      method: 'get',
      params
  })
  return selectAllUsersByDeptId
}
// 工单列表(pc报修工单)
export function repairOrderList(params){  
    const repairOrderList = axios({
        url: '/disData/dataPageList1',
        method: 'get',
        params
    })
    return repairOrderList
}
// 工单列表(pc安检工单)
export function securityOrder(params){  
  const securityOrder = axios({
      url: '/disData/dataPageList2',
      method: 'get',
      params
  })
  return securityOrder
}
// 工单列表(pc巡检工单)
export function checkingOrder(params){  
  const checkingOrder = axios({
      url: '/disData/dataPageList3',
      method: 'get',
      params
  })
  return checkingOrder
}
// 置顶-取消置顶
export function setPcTop(data) {
  const setPcTop = axios({
    url: '/disData/setPcTop',
    method: 'get',
    params: data
  })
  return setPcTop
}
// 工单派工(pc)
export function dataDispatch(data) {
  const dataDispatch = axios({
    url: '/disData/dataDispatch',
    method: 'get',
    params: data
  })
  return dataDispatch
}
// 工单转派(pc)
export function dataTransfer(data) {
  const dataTransfer = axios({
    url: '/disData/dataTransfer',
    method: 'get',
    params: data
  })
  return dataTransfer
}
// 工单回访(pc)
export function dataVisit(data) {
  const dataVisit = axios({
    url: '/disData/dataVisit',
    method: 'post',
    data: data
  })
  return dataVisit
}
// 工单关闭(pc)
export function dataClose(data) {
  const dataClose = axios({
    url: '/disData/dataClose',
    method: 'get',
    params: data
  })
  return dataClose
}
// 工单查看详情（报修工单、安装、其他维修、异常报修）
export function repairOrderDetails(data) {
  const repairOrderDetails = axios({
    url: '/disData/dataDetails1',
    method: 'get',
    params: data
  })
  return repairOrderDetails
}
// 工单查看详情（安检维修）
export function securityOrderDetails(data) {
  const securityOrderDetails = axios({
    url: '/disData/dataDetails2',
    method: 'get',
    params: data
  })
  return securityOrderDetails
}
// PC安检工单提交
export function dataInsertPC(data) {
  const dataInsertPC = axios({
    url: '/disData/dataInsertPC',
    method: 'post',
    data: data
  })
  return dataInsertPC
}
// 工单查看详情（巡检隐患）
export function checkingOrderDetails(data) {
  const checkingOrderDetails = axios({
    url: '/disData/dataDetails3',
    method: 'get',
    params: data
  })
  return checkingOrderDetails
}

//导出pc报修工单
export function exportDataList1(params){  //
  const exportDataList1 = axios({
      url: '/disData/exportDataList1',
      method: 'get',
      params,
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
      },
      responseType: 'blob' //改变接收的值类型
  })
  return exportDataList1
}
//导出pc安检工单
export function exportDataList2(params){  //
  const exportDataList2 = axios({
      url: '/disData/exportDataList2',
      method: 'get',
      params,
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
      },
      responseType: 'blob' //改变接收的值类型
  })
  return exportDataList2
}
//导出pc巡检工单
export function exportDataList3(params){  //
  const exportDataList3 = axios({
      url: '/disData/exportDataList3',
      method: 'get',
      params,
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
      },
      responseType: 'blob' //改变接收的值类型
  })
  return exportDataList3
}
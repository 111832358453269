<template>
  <div>
    <el-dialog
      title="派工"
      :visible.sync="pgdialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :before-close="close"
    >
      <el-form
        ref="pgform"
        :model="pgform"
        label-width="80px"
        :rules="rules"
        v-if="pgdialogVisible"
      >
        <el-form-item label="维修员" prop="userId">
          <el-select
            v-model="pgform.userId"
            placeholder="请选择"
            clearable
            filterable
            style="width: 100%"
          >
            <el-option
              v-for="item in maintainUserList"
              :key="item.id"
              :label="item.realname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="submitpg('pgform')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="转派"
      :visible.sync="zpdialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :before-close="closezp"
    >
      <el-form
        ref="zpform"
        :model="zpform"
        label-width="80px"
        :rules="rules"
        v-if="zpdialogVisible"
      >
        <el-form-item label="受理部门" prop="deptId">
          <el-cascader
            v-model="zpform.deptId"
            :options="sectionList"
            :props="TreeProps"
            size="mini"
            style="width: 100%"
            :show-all-levels="false"
            ref="cascaderHandle"
            @change="selectDepartment"
          >
            <span slot-scope="{ data }">
              {{ data.name }}
            </span>
          </el-cascader>
        </el-form-item>
        <el-form-item label="受理人">
          <el-select
            clearable
            size="mini"
            filterable
            style="width: 100%"
            v-model="zpform.userId"
            placeholder="请选择受理人"
          >
            <el-option
              v-for="item in maintainUserList"
              :key="item.id"
              :label="item.realname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closezp">取 消</el-button>
        <el-button type="primary" @click="submitpg('zpform')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="回访"
      :visible.sync="hfdialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :before-close="closehf"
    >
      <el-form ref="hfform" :model="hfform" label-width="80px" :rules="rules">
        <el-form-item label="回访结果" prop="result">
          <el-radio v-model="hfform.result" label="5">已回访</el-radio>
          <el-radio v-model="hfform.result" label="6">驳回</el-radio>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            placeholder="请输入备注"
            v-model="hfform.remark"
            maxlength="200"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closehf">取 消</el-button>
        <el-button type="primary" @click="submitpg('hfform')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="关闭"
      :visible.sync="gbdialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :before-close="closegb"
    >
      <el-form ref="gbform" :model="gbform" label-width="80px" :rules="rules">
        <el-form-item label="备注" prop="remark">
          <el-input
            type="textarea"
            placeholder="请输入备注"
            v-model="gbform.remark"
            maxlength="200"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closegb">取 消</el-button>
        <el-button type="primary" @click="submitpg('gbform')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="工单处理"
      :visible.sync="maindialogVisible"
      width="439px"
      :close-on-click-modal="false"
      :before-close="closemain"
	  class="maindialog"
    >
      <el-form
        ref="mainform"
        :model="mainform"
        label-width="85px"
        :rules="mainrules"
      >
        <el-form-item label="维修结果" prop="safetyResults" class="results">
          <el-radio-group v-model="mainform.safetyResults" @change="onResults">
            <el-radio label="2">已维修</el-radio>
            <el-radio label="1">未维修</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <div class="item" v-if="mainform.safetyResults == 2"> -->
         	<IssueRegister
			v-if="mainform.safetyResults == 2"
				:data="itemData"
        :service='true'
			></IssueRegister>
        <!-- </div> -->
        <!-- <div class="dispose" v-if="mainform.safetyResults == 2">处理结果：已处理</div> -->
        <el-form-item label="维修员" prop="repairmanId">
		  <el-select
            clearable
            size="mini"
            filterable
            style="width: 100%"
            v-model="mainform.repairmanId"
            placeholder="请选择"
            @change="repairChange"
          >
            <el-option
              v-for="item in maintainUserList"
              :key="item.id"
              :label="item.realname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="维修时间" prop="checkTime">
          <el-date-picker
		   style="width:100%"
            v-model="mainform.checkTime"
            type="datetime"
			size="mini"
			value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
			:picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item> -->
		 <el-form-item label="备注" prop="remark" v-if="mainform.safetyResults == 1">
          <el-input type="textarea" v-model="mainform.remark"  placeholder="请输入未维修原因" maxlength="200"></el-input>
        </el-form-item>
        <el-form-item label="" :prop="mainform.safetyResults==1 ? 'reUrl' : 'woodenLaterUrl' " style="margin:15px 0;" label-width="90px">
			<span slot="label">{{mainform.safetyResults == 1 ? '' :'处理后照片'}}</span>
          <el-upload
              action="#"
              list-type="picture-card"
              :http-request="uploadImgPayProof"
              :on-remove="payProofDelete"
              :on-change="payProofChangeImg"
              :auto-upload="true"
              accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
              :file-list="payProof"
			  :limit='20'
            >
              <div style="height: 20px">
                <img :src="uploadImg" />
              </div>
            </el-upload>
        </el-form-item>
        <el-form-item label="处理说明" v-if="mainform.safetyResults == 2" prop="processingSpecification">
          <el-input type="textarea" v-model="mainform.processingSpecification" maxlength="200" placeholder="请输入"></el-input>
        </el-form-item> 
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closemain">取 消</el-button>
        <el-button type="primary" @click="submitpg('mainform')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  selectAllUsersByDeptId,
  dataDispatch,
  dataTransfer,
  dataVisit,
  dataClose,
  dataInsertPC
} from "@/RequestPort/mainOrder";
import axios from "@/apis/contractApi";
import { selectSysDeptTreeList } from "@/RequestPort/user/user";
import {securityOrderDetails} from "@/RequestPort/mainOrder";
import IssueRegister from "./issueRegister";
export default {
  name: "handleDialog",
  components: { IssueRegister},
  props: {
    pgdialogVisible: {
      type: Boolean,
      default: false,
    },
    zpdialogVisible: {
      type: Boolean,
      default: false,
    },
    hfdialogVisible: {
      type: Boolean,
      default: false,
    },
    gbdialogVisible: {
      type: Boolean,
      default: false,
    },
    dataId: {
      type: [String, Number],
      default: "",
    },
    maindialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
'mainform.woodenLaterUrl': {
    handler (newVal) {
      if(newVal.length){
        this.$refs['mainform'].clearValidate(['woodenLaterUrl'])
      }
    },
    deep: true
  },
  'mainform.reUrl': {
    handler (newVal) {
      if(newVal.length){
        this.$refs['mainform'].clearValidate(['reUrl'])
      }
    },
    deep: true
  }
  },
  data() {
    return {
      TreeProps: {
        label: "name",
        value: "id",
        children: "children",
        emitPath: false,
        checkStrictly: true,
      },
      userTypeList: [],
      maintainUserList: [],
      sectionList: [],
      pgform: {
        userId: "",
      },
      zpform: {
        deptId: "",
        userId: "",
      },
      gbform: {
        remark: "",
      },
      hfform: {
        result: "5",
        remark: "",
      },
      rules: {
        remark: [{ required: true, message: "请选择", trigger: "change" }],
        userId: [{ required: true, message: "请选择", trigger: "change" }],
        deptId: [{ required: true, message: "请选择", trigger: "change" }],
        result: [{ required: true, message: "请选择", trigger: "change" }],
      },
      mainform: {
		safetyResults:'2',
		woodenLaterUrl:[],
		// checkTime: '',
		repairmanId:'',
		reUrl:[],
		remark: '',
	  },
      mainrules: {
		safetyResults: [{ required: true, message: "请选择", trigger: ["change", 'blur'] }],
		repairmanId: [{ required: true, message: "请选择", trigger:"change" }],
		// checkTime:[{ required: true, message: "请选择", trigger: ["change", 'blur'] }],
		remark:[{ required: true, message: "请输入", trigger: ["change", 'blur'] }],
		reUrl: [{required: true, type: 'array', message: '请上传图片',  trigger: ["change", 'blur'] }],
		woodenLaterUrl: [{required: true, type: 'array', message: '请上传图片',  trigger: ["change", 'blur'] }],
	  },
	  uploadImg: require("@/assets/image/upload.png"), //  上传图片默认图标
	  payProof:[],
		itemData: [],
		Register:'',
		pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
}
    };
  },
  methods: {
    repairChange(){
      this.$refs['mainform'].clearValidate(['repairmanId'])
    },
    /**
     * 选择部门
     */
    selectDepartment(val,start) {
      this.maintainUserList = [];
      if (!val) return;
      // 通过部门id查此部门及其下所有部门用户（人员下拉）, code: "aj006"
      selectAllUsersByDeptId({ deptId: val , code: start ? "aj006" : null}).then((res) => {
        this.maintainUserList = res.data;
      });
    },
    getInfo(type, val, list) {
      this.maintainUserList = [];
      this.pgform = {};
      this.zpform = {};
      this.gbform = {};
      this.hfform = {};
	  this.mainform = {
		safetyResults:'2',
		woodenLaterUrl:[],
		// checkTime: '',
		repairmanId:'',
		reUrl:[],
		remark: ''
	  }
	  this.payProof = []
	  this.Register = val
      if (type == 0) {
        this.maintainUserList = list;
      }
      if (type == 1) {
        this.sectionList = list;
      }
    },
    close() {
      this.$emit("close", 0);
      this.pgform = {
        userId: "",
      };
    },
    closezp() {
      this.$emit("close", 1);
      this.zpform = {
        deptId: "",
        userId: "",
      };
    },
    closehf() {
      this.$emit("close", 3);
      this.hfform = {
        result: "5",
        remark: "",
      };
    },
    closegb() {
      this.$emit("close", 4);
      this.gbform = {
        remark: "",
      };
    },
    closemain() {
		this.$refs.mainform.clearValidate();
      this.$emit("close", 5);
    },
    submitpg(pgform) {
      let { dataId } = this;
      this.$refs[pgform].validate((valid) => {
        if (!valid) return;
        if (pgform == "pgform") {
          dataDispatch({ ...this[pgform], dataId }).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.data);
            }
            this.close();
          });
        }
        if (pgform == "zpform") {
          let dataIds = dataId;
          // let dataIds = Number(dataId) ? [dataId] : dataId.split(",");
          // console.log(dataIds,"dataIds")
          
          dataTransfer({ ...this[pgform], dataIds }).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.data);
            }else {
              this.$message.error(res.msg)
            }
            this.closezp();
          });
          // this.$nextTick(() => {
          //   this.closezp();
          // })
         
        }
        if (pgform == "hfform") {
          let dataIds = Number(dataId) ? [dataId] : dataId.split(",");
          dataVisit({ ...this[pgform], dataIds }).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.data);
            }
            this.closehf();
          });
        }
        if (pgform == "gbform") {
          dataClose({ ...this[pgform], dataId }).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.data);
            }
            this.closegb();
          });
        }
		if (pgform == "mainform") {
			let data = {...this[pgform], dataId ,isCache:2}
			data.woodenLaterUrl = data.woodenLaterUrl.length > 0 ? data.woodenLaterUrl.join(',') : ''
			data.reUrl = data.reUrl.length > 0 ? data.reUrl.join(',') :''
      // if(this.mainform.safetyResults == 1 && !data.reUrl) {
      //   this.$message.error("请添加图片")
      //   return
      // }
			dataInsertPC(data).then((res) => {
				if (res.code == 200) {
					this.$message.success(res.data);
				}
        this.closemain()
			})
      
		}
      });
    },
	/**
     * 上传至阿里云
     */
    async uploadImgPayProof(files) {
      let uploadObj = new FormData();
      uploadObj.append("file", files.file);
      let res = await axios.uploadImg(uploadObj);
	  if(this.mainform.safetyResults == 2 ){
      this.mainform.woodenLaterUrl.push(res.data.url);
	  } else {
		this.mainform.reUrl.push(res.data.url);
	  }
    },
	/**
     * 删除文件
     */
    payProofDelete(file) {
      let index = this.getArrayIndex(this.payProof,file);
      this.payProof.splice(index,1)
	  if(this.mainform.safetyResults == 2 ){
      	this.mainform.woodenLaterUrl.splice(index,1)
	  } else {
		this.mainform.reUrl.splice(index,1)
	  }
    },
	payProofChangeImg(file, fileList){
      this.payProof = fileList;
    },
	getArrayIndex(arr, obj) {
      var i = arr.length;
      while (i--) {
        if (arr[i] === obj) {
          return i;
        }
      }
      return -1;
    },
	getsecurityOrderDetails(dataId){
		securityOrderDetails({ dataId }).then((res) => {
			this.itemData = [res.data.scuItem];
		});
	},
	onResults(val){
		this.mainform = {
		safetyResults: val,
		woodenLaterUrl:[],
		// checkTime: '',
		repairmanId: '',
		reUrl:[],
		remark: ''
	  }
	  this.payProof=[]
	  this.$nextTick( () => {
	  	if(this.$refs.mainform) this.$refs.mainform.clearValidate()
	  })
	//   this.$nextTick(() => {
	 
	//   })
	}
  },
};
</script>

<style lang="scss" scoped>
.maindialog{
	::v-deep .el-dialog__body{
		padding: 0 20px;
	}
	.results{
		margin-bottom: 0;
		
	}
	::v-deep .issueRegister .content-list .list{
		margin-bottom: 0px;
		padding:19px 26px 5px 19px;
		background: #f4f5f7;
	}
	::v-deep .issueRegister .content-list .abnormal-item .item{
		padding: 0;
	}
	.el-form-item{
		margin-bottom: 10px;
	}
	::v-deep .el-form-item__error{
		line-height: 0;
	}
	// ::v-deep .el-form-item__error{
	// 	display: none;
	// }	
	::v-deep .el-dialog__body{
		max-height: 650px;
		overflow: auto;
	}
	::v-deep .el-upload-list__item{width: 100px !important;height: 100px;}
	::v-deep .el-upload--picture-card{width: 100px !important;height: 100px;line-height: 100px;}
.item{
	padding: 10px;
    background: #f4f5f7;
    line-height: 25px;
	.itemTitle{
		font-size: 16px;
		font-weight: bold;
	}
	.abnormal{
		color: red;
	}
}
	.dispose{
		margin: 12px 20px;
	}
}
</style>
<template>
	<div class="issueRegister">
		<div class="content-list">
			<div class="list" v-for="(item, index) in data" :key="index">
				<div class="title-text">{{ item.entryTypeName }}</div>
				<div
					class="content-item"
					v-for="list in item.entryTypeList"
					:key="list.id"
				>
					<div class="content-title">
						{{ list.content }}:
						<span
							style="margin-left: 5px"
							:class="{ abnormal: list.woodenUrl != null }"
							>{{ list.woodenUrl ? "是" : "否" }}</span
						>
						<i
							v-if="list.woodenUrl"
							class="el-icon-warning icon-img"
							style="color: #f56c6c"
						></i>
						<i
							v-else
							class="el-icon-success icon-img"
							style="color: #47ab40"
						></i>
					</div>
					<div v-if="list.woodenUrl" class="abnormal-item">
						<div class="item">
							<div>
								隐患等级：{{
									list.writeLevel
										? list.writeLevel
										: list.level
								}}级
							</div>
							<div>
								<div>隐患处照片：</div>
								<div class="imgContain">
									<div
										v-for="(
											item, index
										) in list.woodenUrl.split(',')"
										:key="item"
										style="margin-right: 10px"
									>
										<el-image
											style="
												width: 100px;
												height: 100px;
												border: 1px solid #dddddd;
												margin: 5px;
											"
											:src="item"
											:preview-src-list="
												list.woodenUrl.split(',')
											"
											:z-index="index"
										>
										</el-image>
									</div>
								</div>
							</div>
							<div>隐患说明：{{ list.woodenState }}</div>
							<div>
								整改方式：{{
									list.woodenAbarbeitung == 1
										? "用户自行整改"
										: "公司整改"
								}}
							</div>
							<div v-if="!service">
								处理结果：{{
									list.woodenManage == 1 || list.woodenManage == 3 ? "未处理" :  "已处理"
								}}
							</div>
							<div v-if="list.woodenManage == 2 && !service">
								<div>处理后照片：</div>
								<div
									class="imgContain"
									v-if="list.woodenLaterUrl"
								>
									<div
										v-for="(
											img, index
										) in list.woodenLaterUrl.split(',')"
										:key="img"
										style="margin-right: 10px"
									>
										<el-image
											style="
												width: 100px;
												height: 100px;
												border: 1px solid #dddddd;
												margin: 5px;
											"
											v-if="img"
											:src="img"
											:preview-src-list="
												list.woodenLaterUrl.split(',')
											"
											:z-index="index"
										>
										</el-image>
									</div>
								</div>
								<div>
									处理说明：{{ list.processingSpecification }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "issueRegister",
		props: {
			data: {
				type: Array,
				default: () => [],
			},
			service:{
				type: Boolean,
      			default: false,
			}
		},
		methods: {},
	};
</script>

<style scoped lang="less">
	.ycx {
		line-height: 46px;
		background: #fffaf7;
		border: 1px solid #f7e1c8;
		border-radius: 6px;
		margin: 20px 0;
		padding-left: 15px;
		color: #ff821d;
	}
	.issueRegister {
		.title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 5px;
			.text {
				font-size: 16px;
				font-weight: bolder;
			}
		}
		.content-list {
			.imgContain {
				display: flex;
				flex-wrap: wrap;
				margin-top: 10px;
			}
			.list {
				margin-bottom: 20px;
        padding: 0 40px;
			}
			.title-text {
				font-size: 14px;
				font-weight: bolder;
				margin-bottom: 5px;
			}
			.content-item {
				margin-bottom: 10px;
				.content-title {
					margin-bottom: 5px;
				}
				.abnormal {
					color: red;
				}
			}
			.abnormal-item {
				padding: 0px;
				.item {
					padding: 10px;
					background: #f4f5f7;
					line-height: 25px;
				}
			}
		}
	}
	.icon-img {
		font-size: 15px;
		margin-left: 5px;
	}
</style>